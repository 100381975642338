import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSkipHandler() {
  return (
    <>
      <Helmet>
        <title>A39 Skip Handler | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Skip Handler A39 training course at Alpha Construction Training to learn essential skills for operating skip handlers safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A39 Skip Handler</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Skip Handler A39 training course is designed to provide
              participants with the essential knowledge and hands-on experience
              needed to safely and efficiently operate skip handlers in
              construction, waste management, and industrial environments. This
              course ensures compliance with safety regulations, covering best
              practices for handling, transporting, and unloading skips in
              various working conditions.
            </p>
            <br />
            <p>
              Whether you are new to skip handler operations or looking to
              refine your skills, this course will equip you with the technical
              expertise required to perform daily tasks efficiently and minimize
              workplace hazards.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Gain an in-depth understanding of skip handler components,
                controls, and functions for optimal performance and safety.
              </li>
              <li>
                Learn the correct operational techniques for loading, securing,
                transporting, and unloading skips with precision.
              </li>
              <li>
                Understand site-specific safety procedures, hazard
                identification, and risk management strategies.
              </li>
              <li>
                Master the legal requirements and industry regulations governing
                skip handler operations, including PUWER and LOLER compliance.
              </li>
              <li>
                Develop skills to assess load capacity, balance skips
                effectively, and prevent tipping or instability during
                transportation.
              </li>
              <li>
                Learn how to carry out daily maintenance checks, diagnose
                mechanical faults, and ensure the longevity of equipment.
              </li>
              <li>
                Adapt skip handling techniques for various work environments,
                including construction sites, recycling facilities, and urban
                areas.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a combination of classroom-based instruction,
              hands-on practical training, and competency evaluations.
              Candidates will be assessed on their ability to operate a skip
              handler safely and effectively in real-world scenarios.
            </p>
            <br />
            <p>
              The assessment consists of a CPCS technical test, which evaluates
              both theoretical understanding and practical proficiency.
              Candidates must demonstrate the ability to maneuver a skip
              handler, follow safety protocols, and respond to site-specific
              challenges.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and technical test,
              participants will receive a Red CPCS Trained Operator Card, valid
              for two years. This certification confirms competency in skip
              handler operations and is recognized across the industry.
            </p>
            <br />
            <p>
              To upgrade to a Blue CPCS Competence Card, candidates must
              complete an NVQ Level 2 in Plant Operations within the two-year
              validity period. The Blue Card remains valid for five years and is
              renewable through refresher training.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Skip Handler A39 training course provides comprehensive
              instruction in skip handling techniques, safety regulations, and
              operational best practices. This course is suitable for
              individuals at all experience levels, from beginners to
              experienced operators seeking official certification.
            </p>
            <br />
            <p>
              By completing this training, participants will gain the necessary
              skills to safely operate skip handlers, ensuring compliance with
              industry regulations and enhancing workplace safety. The course
              offers a balance of theory and hands-on practice, preparing
              candidates for real-world skip handling tasks.
            </p>
            <br />
            <p>
              Enroll today to develop your expertise, obtain industry-recognized
              certification, and take the next step in your construction or
              waste management career.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
