import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSReachTruckForklift() {
  return (
    <>
      <Helmet>
        <title>A18 Reach Truck Forklift | Alpha Construction Training</title>
        <meta
          name="description"
          content="Gain formal qualifications with the A18 CPCS Reach Truck Forklift training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A18 Reach Truck Forklift</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A18 Reach Truck Forklift course is designed to provide
              participants with the knowledge and practical skills required for
              safe and effective operation of a reach truck forklift in a
              variety of workplace environments. Whether you are new to
              operating forklifts or looking to formalize your experience, this
              course ensures compliance with industry standards and safety
              regulations.
            </p>
            <br />
            <p>
              The course duration varies depending on the learner’s prior
              experience, ensuring that both beginners and experienced operators
              receive the necessary training tailored to their skill level.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the responsibilities and role of a forklift operator
                in material handling and logistics.
              </li>
              <li>
                Identify key components, controls, and the basic construction of
                a reach truck forklift.
              </li>
              <li>
                Follow manufacturer guidelines, operator’s handbook
                instructions, and relevant regulations to ensure safe operation.
              </li>
              <li>
                Conduct pre-use inspections and safety checks to confirm
                operational readiness.
              </li>
              <li>
                Recognize and utilize essential documentation, such as risk
                assessments and safety checklists.
              </li>
              <li>
                Configure and prepare the forklift for safe travel, including
                navigating tight and confined spaces.
              </li>
              <li>
                Assess, plan, and execute lifting and transferring operations
                efficiently and safely.
              </li>
              <li>
                Lift, transport, and remove various types of loads, including
                those positioned at full working height.
              </li>
              <li>
                Accurately place, stack, and retrieve loads at designated
                locations.
              </li>
              <li>
                Implement best practices to maintain a clean, hazard-free, and
                organized workspace.
              </li>
              <li>
                Perform proper shut-down procedures and secure the forklift for
                storage or transportation.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course combines theoretical instruction with hands-on
              practical exercises to develop proficiency in reach truck forklift
              operation. Candidates will complete a one-day assessment that
              includes CPCS technical tests designed to evaluate their ability
              to operate the machine safely and efficiently.
            </p>
            <br />
            <p>
              The assessment covers both written and practical evaluations,
              ensuring candidates demonstrate competency in key areas such as
              maneuvering, load handling, and safety compliance.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully passing the technical tests, learners will
              receive a Red CPCS Trained Operator Card, valid for two years.
              This certification confirms that the operator has received
              industry-approved training and can operate a reach truck forklift
              in a workplace setting.
            </p>
            <br />
            <p>
              To advance their qualification, operators must complete an NVQ
              Level 2 in Plant Operations before the Red Card expires. This will
              allow them to obtain the Blue CPCS Competence Card, signifying
              full professional competency in forklift operation.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A18 Reach Truck Forklift course equips individuals with the
              knowledge and skills necessary for safe and effective operation of
              reach truck forklifts. The training follows industry best
              practices, covering everything from basic controls to advanced
              material handling techniques.
            </p>
            <br />
            <p>
              This course is ideal for those seeking to enter the logistics,
              warehousing, and construction industries or for current operators
              looking to achieve formal certification. By completing this
              training, participants will enhance their career opportunities and
              ensure compliance with workplace safety standards.
            </p>
            <br />
            <p>
              Enroll today to gain your CPCS certification and take the next
              step in your career in material handling and logistics.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
