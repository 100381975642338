import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/cpcs.css";
import "../css/machine.css";
import { Helmet } from "react-helmet";
import crawlerCrane from "../images/a02-crawlercrane.png";
import industrialForklift from "../images/a16-industrialforklift.jpg";
import agriculturalTractor from "../images/a33-agriculturaltractor.webp";
import forwardTippingDumper from "../images/a09-forwardtippingdumper.png";
import liftingOperations from "../images/a61-liftingoperations.png";
import plantDriving from "../images/a68-plantdriving.jpg";
import towerCrane from "../images/a04-towercrane.png";
import plantMarshaller from "../images/a73-plantmarshaller.webp";
import excavator180Above5Tonnes from "../images/a12-excavator180above5tonnes.jpg";
import excavator180below5Tonnes from "../images/a10-excavator180below5tonnes.jpg";
import roughTerrainForklift from "../images/a14-roughterrainforklift.jpg";
import sideLoader from "../images/a15-sideloader.jpg";
import reachTruckForklift from "../images/a18-reachtruckforklift.jpg";
import grader from "../images/a19-grader.jpg";
import hoist from "../images/a20-hoist.webp";
import wheeledShovel from "../images/a21-wheeledshovel.jpg";
import trackedLoadingShovel from "../images/a22-trackedloadingshovel.jpg";
import skidSteerLoader from "../images/a23-skidsteerloader.jpg";
import motorisedScraper from "../images/a24-motorisedscraper.jpg";
import mewpScissor from "../images/a25-mewpscissor.jpg";
import mewpMasterClimber from "../images/a27-mewpmasterclimber.jpg";
import pilingRigTripod from "../images/a30-pilingrigtripod.jpg";
import crawlerTractorDozer from "../images/a34-crawlertractordozer.jpg";
import crawlerTractorSideBoom from "../images/a35-crawlertractorsideboom.jpg";
import trencher from "../images/a37-trencher.webp";
import skipHandler from "../images/a39-skiphandler.webp";
import slingerSignaller from "../images/a40-slingersignaller.webp";
import loaderCompressor from "../images/a41-loadercompressor.jpg";
import crusher from "../images/a42-crusher.webp";
import screener from "../images/a43-screener.jpg";
import trailerMountedConcretePump from "../images/a44-trailermountedconcretepump.jpg";
import pilingRigDrivenBelow20Tonnes from "../images/a45-pilingrigdrivenbelow20tonnes.jpg";
import pilingRigDrivenAbove20Tonnes from "../images/a46-pilingrigdrivenabove20tonnes.jpg";
import pilingRigDrivenBoredBelow20Tonnes from "../images/a47-pilingrigboredbelow20tonnes.jpg";
import loaderSecurer from "../images/a50-loadersecurer.jpg";
import telescopicHandler from "../images/a17-telescopichandler.png";
import rideOnRoller from "../images/a31-rideonroller.jpg";
import mewpBoom from "../images/a26-mewpboom.webp";
import dragline from "../images/a05-dragline.jpg";
import dumpTruckArticulated from "../images/a56-dumptruckarticulated.jpg";
import dumpTruckRigid from "../images/a57-dumptruckrigid.webp";
import excavator360Above10Tonnes from "../images/a59-excavator360above10tonnes.png";
import excavator360Below10Tonnes from "../images/a58-excavator360below10tonnes.jpg";
import mobileCrane from "../images/a60-mobilecrane.webp";
import compactCrane from "../images/a66-compactcrane.jpg";
import pilingRigAttendant from "../images/a74-pilingrigattendant.jpg";
import concretePlacingBoom from "../images/a72-concreteplacingboom.jpg";
import demolitionPlant from "../images/d90-demolitionplant.webp";
import skidSteerToolCarrier from "../images/d92-skidsteertoolcarrier.jpg";
import pilingRigBoredAbove20Tonnes from "../images/a48-pilingrigboredabove20tonnes.jpg";
import soilLandFillCompactor from "../images/a32-soillandfillcompactor.jpg";
import craneSupervisor from "../images/a62-cranesupervisor.png";
import overheadCrane from "../images/a64-overheadcrane.png";
import tunnellingLocomotives from "../images/a67-tunellinglocomotives.jpg";
import soilStabiliser from "../images/a71-soilstabiliser.jpg";
import conveyorPump from "../images/a75-conveyorpump.jpg";
import telescopicHandler360Slew from "../images/a77-telescopichandler360slew.jpg";
import vacuumExcavator from "../images/a78-vacuumexcavator.jpg";
import demolitionPlantPedestrianOperated from "../images/d91-demolitionplantpedestrianoperated.jpg";

export default function Cpcs() {
  return (
    <>
      <Helmet>
        <title>CPCS | Alpha Construction Training</title>
        <meta
          name="description"
          content="Explore comprehensive CPCS training and assessment options at Alpha Construction Training. Learn about the Construction Plant Competence Scheme (CPCS), requirements, theory and practical assessments, and various machinery categories for plant operators across the UK."
        />
      </Helmet>
      <Navbar />
      <div className="cpcs-container">
        <div className="cpcs-hero">
          <h1>CPCS Training & Assessments</h1>
        </div>
        <div className="cpcs-content">
          <div className="cpcs-info">
            <div className="cpcs-description">
              <h2>What is CPCS?</h2>
              <p>
                The Construction Plant Competence Scheme (CPCS) is a highly
                regarded, card-based certification program that plays a key role
                in verifying the competence and skills of plant operators in the
                UK. As the largest and most widely recognized certification
                scheme in the plant industry, CPCS ensures that operators
                possess the necessary technical knowledge, practical skills, and
                health and safety awareness to safely and efficiently operate
                plant machinery.
              </p>
              <br />
              <p>
                The CPCS program is designed to assess both theoretical
                knowledge and practical abilities, ensuring that operators can
                demonstrate their competence in a range of machine operations.
                It includes a combination of theoretical tests, practical
                assessments, and real-world evaluations, all conducted under
                strict safety and industry standards.
              </p>
              <br />
              <p>
                Our CPCS training courses are competitively priced to ensure
                accessibility for all operators, and we also offer attractive
                discounts for those who are undertaking multiple tests. This
                allows operators to gain multiple certifications while managing
                training costs effectively.
              </p>
            </div>
          </div>
          <div className="cpcs-requirements">
            <div className="requirements-content">
              <h2>Introduction</h2>
              <p>
                The Construction Plant Competence Scheme (CPCS) is one of the
                most recognized and respected certification programs for plant
                operators in the UK. To begin any training or assessments within
                this scheme, candidates are required to hold the CITB Health &
                Safety Certification. This certification ensures that all
                candidates have a solid understanding of the fundamental health
                and safety practices necessary for working on construction sites
                and operating plant machinery.
              </p>
              <br />
              <p>
                The CITB Health & Safety Test serves as a foundational
                requirement for the CPCS qualification process. It is designed
                to assess a candidate's knowledge of key safety procedures and
                risk management protocols specific to the construction and plant
                operations industries. The test covers topics such as site
                safety, safe working practices, emergency response procedures,
                and the safe operation of plant machinery. Without successfully
                completing this test, candidates cannot proceed with the CPCS
                qualification or apply for any CPCS Cards.
              </p>
              <br />
              <p>
                By ensuring that all candidates meet these health and safety
                standards, the CPCS and CITB work together to maintain a high
                level of safety and competence across the construction industry.
                This requirement is in place to protect workers, reduce the risk
                of accidents, and ensure that operators are properly prepared
                for the responsibilities of their roles.
              </p>
            </div>
            <div className="learning-outcomes">
              <h2>About the CPCS Assessment</h2>
              <h3 className="assessment-heading">CPCS Theory Test</h3>
              <ul>
                <li>
                  The CPCS Theory Test is a verbal assessment that measures a
                  candidate's understanding of plant machinery or their specific
                  role in operations.
                </li>
                <li>
                  This test is conducted in person and is audio recorded.
                  Candidates are provided with a copy of the questions in
                  sequence and must respond verbally without writing their
                  answers.
                </li>
                <li>
                  The number of questions depends on the category, most tests
                  include 40 questions. These are divided into:
                </li>
                <li>15 safety-related questions specific to the category</li>
                <li>
                  25 general knowledge questions selected from a broader
                  question pool
                </li>
                <li>
                  The standard duration of the test is 30 minutes. However,
                  additional time may be granted for candidates requiring
                  support or interpreters.
                </li>
                <li>
                  To pass the test, candidates must achieve the minimum required
                  number of correct answers.
                </li>
              </ul>
              <h3 className="assessment-heading">CPCS Practical Test</h3>
              <ul>
                <li>
                  The CPCS Practical Test is a hands-on evaluation designed to
                  assess a candidate's ability to safely and effectively operate
                  machinery in compliance with CPCS standards. The test includes
                  a series of exercises that simulate real-life tasks, ensuring
                  candidates are prepared for actual worksite operations.
                </li>
                <li>
                  Before beginning the test, candidates must complete a pre-test
                  briefing where the assessor explains the tasks, safety
                  expectations, and rules. Candidates are given an opportunity
                  to ask questions to clarify the requirements.
                </li>
                <li>
                  The test starts with pre-start checks, where candidates must
                  inspect the machinery for any defects or issues, such as fluid
                  levels, tire conditions, and operational functionality. This
                  demonstrates the candidate's understanding of essential
                  maintenance and safety practices.
                </li>
                <li>
                  Following the pre-start checks, candidates move on to
                  traveling tasks, which involve maneuvering the machinery
                  across various terrains, ensuring stability, avoiding
                  obstacles, and demonstrating awareness of the environment.
                  These tasks evaluate control and precision.
                </li>
                <li>
                  During the setup for work phase, candidates prepare the
                  machine for a specific task, such as fitting attachments or
                  positioning the equipment correctly. This part of the test
                  measures attention to detail and the ability to follow
                  instructions.
                </li>
                <li>
                  The operating phase focuses on task execution, where
                  candidates are required to use the machinery to perform
                  specific operations, such as lifting, digging, loading, or
                  unloading. This phase tests the candidate's skill, efficiency,
                  and ability to follow safety protocols.
                </li>
                <li>
                  Finally, the test concludes with shutdown procedures, where
                  candidates safely power down and secure the machinery,
                  including parking it in a designated area and performing
                  post-operation checks to ensure it is safe for future use.
                </li>
                <li>
                  Throughout the test, candidates are closely monitored by a
                  certified CPCS assessor who evaluates their performance
                  against standardized criteria. The assessor ensures that all
                  tasks are completed in accordance with health and safety
                  regulations and industry best practices.
                </li>
                <li>
                  The duration of the practical test depends on the type of
                  machinery being assessed. For example, operating a forklift
                  may take less time compared to a complex piece of equipment
                  like a crane. Candidates must demonstrate competence in all
                  areas to successfully pass the test and earn their
                  certification.
                </li>
              </ul>
            </div>
          </div>
          <div className="cpcs-categories">
            <div className="categories-list">
              <div className="category">
                <h2>NPORS Categories</h2>
                <div className="button-list">
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-crawlercrane")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text"> A02 Crawler Crane</div>
                      <div class="button-image">
                        <img src={crawlerCrane} alt="Crawler Crane" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-industrialforklift")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A16 Industrial Forklift</div>
                      <div class="button-image">
                        <img
                          src={industrialForklift}
                          alt="Industrial Forklift"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-agriculturaltractor")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text"> A33 Agricultural Tractor</div>
                      <div class="button-image">
                        <img
                          src={agriculturalTractor}
                          alt="Agricultural Tractor"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-forwarddumper")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A09 Forward Tipping Dumper</div>
                      <div class="button-image">
                        <img
                          src={forwardTippingDumper}
                          alt="Foward Tipping Dumper"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-liftingoperations")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A61 Lifting Operations</div>
                      <div class="button-image">
                        <img src={liftingOperations} alt="Lifting Operations" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-plantdriving")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A68 Plant Driving</div>
                      <div class="button-image">
                        <img src={plantDriving} alt="Plant Driving" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-towercrane")}
                  >
                    <div class="button-content">
                      <div class="button-text">A04 Tower Crane</div>
                      <div class="button-image">
                        <img src={towerCrane} alt="Tower Crane" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-plantmarshaller")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A73 Plant Marshaller</div>
                      <div class="button-image">
                        <img src={plantMarshaller} alt="Plant Marshaller" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-excavatorabove5t")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A12 Excavator 180 Above 5T</div>
                      <div class="button-image">
                        <img
                          src={excavator180Above5Tonnes}
                          alt="Excavator 180 Above 5 Tonnes"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-excavatorbelow5t")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A10 Excavator Below 5T</div>
                      <div class="button-image">
                        <img
                          src={excavator180below5Tonnes}
                          alt="Excavator 180 Below 5 Tonnes"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-roughterrainforklift")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A14 Rough Terrain Forklift</div>
                      <div class="button-image">
                        <img
                          src={roughTerrainForklift}
                          alt="Rough Terrain Forklift"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-sideloader")}
                  >
                    <div class="button-content">
                      <div class="button-text"> A15 Side Loader</div>
                      <div class="button-image">
                        <img src={sideLoader} alt="Side Loader" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-reachtruck")}
                  >
                    <div class="button-content">
                      <div class="button-text">A18 Reach Truck Forklift</div>
                      <div class="button-image">
                        <img
                          src={reachTruckForklift}
                          alt="Reach Truck Forklift"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-grader")}
                  >
                    <div class="button-content">
                      <div class="button-text">A19 Grader</div>
                      <div class="button-image">
                        <img src={grader} alt="Grader" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-hoist")}
                  >
                    <div class="button-content">
                      <div class="button-text">A20 Hoist</div>
                      <div class="button-image">
                        <img src={hoist} alt="Hoist" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-wheeledshovel")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A21 Wheeled Shovel</div>
                      <div class="button-image">
                        <img src={wheeledShovel} alt="Wheeled Shovel" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-trackedloadingshovel")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A22 Tracked Loading Shovel</div>
                      <div class="button-image">
                        <img
                          src={trackedLoadingShovel}
                          alt="Tracked Loading Shovel"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-skidsteerloader")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A23 Skid Steer Loader</div>
                      <div class="button-image">
                        <img src={skidSteerLoader} alt="Skid Steer Loader" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-motorisedscraper")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A24 Motorised Scraper</div>
                      <div class="button-image">
                        <img src={motorisedScraper} alt="Motorised Scraper" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-mewpscissor")}
                  >
                    <div class="button-content">
                      <div class="button-text">A25 MEWP Scissor</div>
                      <div class="button-image">
                        <img src={mewpScissor} alt="MEWP Scissor" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-mewpmastclimber")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A27 MEWP Master Climber</div>
                      <div class="button-image">
                        <img
                          src={mewpMasterClimber}
                          alt="MEWP Master Climber"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigtripod")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A30 Piling Rig Tripod</div>
                      <div class="button-image">
                        <img src={pilingRigTripod} alt="Piling Rig Tripod" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-crawlertractordozer")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A34 Crawler - Tractor/Dozer</div>
                      <div class="button-image">
                        <img
                          src={crawlerTractorDozer}
                          alt="Crawler - Tractor/Dozer"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-crawlertractorsideboom")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        A35 Crawler Tractor Side Boom
                      </div>
                      <div class="button-image">
                        <img
                          src={crawlerTractorSideBoom}
                          alt="Crawler Tractor Side Boom"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-trencher")}
                  >
                    <div class="button-content">
                      <div class="button-text">A37 Trencher</div>
                      <div class="button-image">
                        <img src={trencher} alt="Trencher" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-skiphandler")}
                  >
                    <div class="button-content">
                      <div class="button-text">A39 Skip Handler</div>
                      <div class="button-image">
                        <img src={skipHandler} alt="Skip Handler" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-slingersignaller")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A40 Slinger Signaller</div>
                      <div class="button-image">
                        <img src={slingerSignaller} alt="Slinger Signaller" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-loadersignaller")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A41 Loader Compressor</div>
                      <div class="button-image">
                        <img src={loaderCompressor} alt="Loader Compressor" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-crusher")}
                  >
                    <div class="button-content">
                      <div class="button-text">A42 Crusher</div>
                      <div class="button-image">
                        <img src={crusher} alt="Crusher" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-screener")}
                  >
                    <div class="button-content">
                      <div class="button-text">A43 Screener</div>
                      <div class="button-image">
                        <img src={screener} alt="Screener" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-trailermountedpump")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        A44 Trailer Mounted Concrete Pump
                      </div>
                      <div class="button-image">
                        <img
                          src={trailerMountedConcretePump}
                          alt="Trailer Mounted Concrete Pump"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigdrivenbelow20t")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        A45 Piling Rig Driven Below 20 Tonnes
                      </div>
                      <div class="button-image">
                        <img
                          src={pilingRigDrivenBelow20Tonnes}
                          alt="Piling Rig Driven Below 20 Tonnes"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigdrivenabove20t")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        A46 Piling Rig Driven Above 20 Tonnes
                      </div>
                      <div class="button-image">
                        <img
                          src={pilingRigDrivenAbove20Tonnes}
                          alt="Piling Rig Driven Above 20 Tonnes"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigboredbelow20t")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        A47 Piling Rig Bored Below 20 Tonnes
                      </div>
                      <div class="button-image">
                        <img
                          src={pilingRigDrivenBoredBelow20Tonnes}
                          alt="Piling Rig Driven Bored Below 20 Tonnes"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-loadersecurer")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A49 Loader Securer</div>
                      <div class="button-image">
                        <img src={loaderSecurer} alt="Loader Securer" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-telescopichandler")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A17 Telescopic Handler</div>
                      <div class="button-image">
                        <img src={telescopicHandler} alt="Telescopic Handler" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-rideonroller")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A31 Ride-on-Roller</div>
                      <div class="button-image">
                        <img src={rideOnRoller} alt="Ride-on-Roller" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-mewpboom")}
                  >
                    <div class="button-content">
                      <div class="button-text">A26 MEWP Boom</div>
                      <div class="button-image">
                        <img src={mewpBoom} alt="MEWP Boom" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-dragline")}
                  >
                    <div class="button-content">
                      <div class="button-text">A05 Dragline</div>
                      <div class="button-image">
                        <img src={dragline} alt="Dragline" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-dumptruckarticulated")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A56 Dump Truck Articulated</div>
                      <div class="button-image">
                        <img
                          src={dumpTruckArticulated}
                          alt="Dump Truck Articulated"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-dumptruckrigid")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A57 Dump Truck Rigid</div>
                      <div class="button-image">
                        <img src={dumpTruckRigid} alt="Dump Truck Rigid" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-excavator360above10t")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A59 Excavator 360 Above 10T</div>
                      <div class="button-image">
                        <img
                          src={excavator360Below10Tonnes}
                          alt="Excavator 360 Above 10T"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-excavator360below10t")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A58 Excavator 360 Below 10T</div>
                      <div class="button-image">
                        <img
                          src={excavator360Above10Tonnes}
                          alt="Excavator 360 Below 10T"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-mobilecrane")}
                  >
                    <div class="button-content">
                      <div class="button-text">A60 Mobile Crane</div>
                      <div class="button-image">
                        <img src={mobileCrane} alt="Mobile Crane" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-compactcrane")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A66 Compact Crane</div>
                      <div class="button-image">
                        <img src={compactCrane} alt="Compact Crane" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigattendant")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A74 Piling Rig Attendant</div>
                      <div class="button-image">
                        <img
                          src={pilingRigAttendant}
                          alt="Piling Rig Attendant"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-concreteplacingboom")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A72 Concrete Placing Boom</div>
                      <div class="button-image">
                        <img
                          src={concretePlacingBoom}
                          alt="Concrete Placing Boom"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-demolitionplant")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">D90 Demolition Plant</div>
                      <div class="button-image">
                        <img src={demolitionPlant} alt="Demolition Plant" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-skidsteertoolcarrier")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">D92 Skid Steer Tool Carrier</div>
                      <div class="button-image">
                        <img
                          src={skidSteerToolCarrier}
                          alt="D92 Skid Steer Tool Carrier"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigboredabove20t")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        A48 Piling Rig Bored Above 20 Tonnes
                      </div>
                      <div class="button-image">
                        <img
                          src={pilingRigBoredAbove20Tonnes}
                          alt="Piling Rig Bored Above 20 Tonnes"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-soillandfillcompactor")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A32 Soil LandFill Compactor</div>
                      <div class="button-image">
                        <img
                          src={soilLandFillCompactor}
                          alt="Soil LandFill Compactor"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-cranesupervisor")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A62 Crane Supervisor</div>
                      <div class="button-image">
                        <img src={craneSupervisor} alt="Crane Supervisor" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-overheadcrane")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A64 Overhead Crane</div>
                      <div class="button-image">
                        <img src={overheadCrane} alt="Overhead Crane" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-tunellinglocomotives")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A67 Tunnelling Locomotives</div>
                      <div class="button-image">
                        <img
                          src={tunnellingLocomotives}
                          alt="Tunnelling Locomotives"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-soilstabiliser")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A71 Soil Stabiliser</div>
                      <div class="button-image">
                        <img src={soilStabiliser} alt="Soil Stabiliser" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-conveyorpump")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A75 Conveyor Pump</div>
                      <div class="button-image">
                        <img src={conveyorPump} alt="Conveyor Pump" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-telescopichandler360slew")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        A77 Telescopic Handler 360 Slew
                      </div>
                      <div class="button-image">
                        <img
                          src={telescopicHandler360Slew}
                          alt="Telescopic Handler 360 Slew"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-vacuumexcavator")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A78 Vacuum Excavator</div>
                      <div class="button-image">
                        <img src={vacuumExcavator} alt="Vacuum Excavator" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href =
                        "/cpcs-demolitionplantpedestrianoperated")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">
                        D91 Demolition Plant Pedestrian Operated
                      </div>
                      <div class="button-image">
                        <img
                          src={demolitionPlantPedestrianOperated}
                          alt="Demolition Plant Pedestrian Operated"
                        />
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
