import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSGrader() {
  return (
    <>
      <Helmet>
        <title>A19 Grader | Alpha Construction Training</title>
        <meta
          name="description"
          content="Gain formal qualifications with the A19 CPCS Grader training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A19 Grader</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A19 Grader course is designed to provide participants with the
              skills and knowledge required to safely and efficiently operate a
              grader on construction sites. Whether you are a novice operator or
              have experience working with graders, this course provides the
              formal qualifications needed to operate these machines in
              compliance with safety regulations.
            </p>
            <br />
            <p>
              The course includes both theoretical learning and practical
              exercises tailored to both beginners and experienced operators. It
              covers essential topics like safety procedures, equipment
              handling, and grading techniques.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the role of a plant operator within the construction
                sector and the importance of safety.
              </li>
              <li>
                Identify the principal components of a grader, understand its
                basic construction, and familiarize yourself with the relevant
                terminology.
              </li>
              <li>
                Adhere to the manufacturer’s instructions as specified in the
                operator’s handbook and other safety regulations.
              </li>
              <li>
                Conduct thorough pre-use checks to ensure the grader is fit for
                operation.
              </li>
              <li>
                Set up and configure the grader for safe travel on-site,
                ensuring all settings are appropriate for the environment.
              </li>
              <li>
                Operate the grader over rough, undulating ground and flat
                surfaces with confidence.
              </li>
              <li>
                Maneuver the grader in tight and confined spaces safely and
                effectively.
              </li>
              <li>
                Understand grading techniques, side-casting, and the process for
                handling high bank work.
              </li>
              <li>
                Grade, spread, and level materials and ground to create even and
                safe surfaces.
              </li>
              <li>
                Maintain a clean and organized work environment while addressing
                any potential hazards.
              </li>
              <li>
                Perform shut-down procedures, secure the grader for transport,
                and follow site safety protocols when the job is completed.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course involves both theoretical and practical sessions to
              ensure that participants have a comprehensive understanding of
              grader operations. After completing the course, candidates will
              take a one-day assessment consisting of CPCS technical tests,
              which include practical and written evaluations to test their
              competence in grader operation and safety procedures.
            </p>
            <br />
            <p>
              The assessment focuses on demonstrating competency in real-world
              conditions, covering all aspects of operating a grader, from
              pre-use checks to maneuvering and grading tasks.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the assessment, learners will
              receive a Red CPCS Trained Operator Card, which is valid for two
              years. This card confirms that the holder has received proper
              training and is capable of safely operating a grader in the
              workplace.
            </p>
            <br />
            <p>
              To upgrade to a Blue CPCS Competence Card, learners must complete
              an NVQ Level 2 in Plant Operations within the two-year validity
              period of the Red Card. The Blue Card signifies that the operator
              has achieved a higher level of competence and experience.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A19 Grader course provides comprehensive training for those
              seeking to become qualified grader operators. It combines
              theoretical knowledge with practical experience to ensure that
              participants are well-prepared to safely and efficiently operate
              graders on construction sites.
            </p>
            <br />
            <p>
              With a focus on safety and technical competency, this course
              offers essential skills for anyone looking to advance their career
              in earthmoving and site preparation. Whether you're new to grading
              or have previous experience, this course will equip you with the
              knowledge and qualifications needed to work confidently and safely
              on construction sites.
            </p>
            <br />
            <p>
              Enroll today to gain your CPCS certification and take the next
              step in your career as a grader operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
