import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSExcavator180Below5T() {
  return (
    <>
      <Helmet>
        <title>A10 CPCS Excavator 180º Below 5 Tonnes</title>
        <meta
          name="description"
          content="Master the skills required to operate Excavators with the Excavator 180º below 5 tonnes - A10 training course at Alpha Construction Training. Achieve industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A10 CPCS Excavator 180º Below 5 Tonnes</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A10 CPCS Excavator 180º Below 5 Tonnes course is designed to
              provide operators with the essential skills and knowledge required
              to operate excavators safely and efficiently in compliance with
              industry standards. The training covers fundamental operational
              techniques, workplace safety protocols, and machine maintenance
              procedures.
            </p>
            <br />
            <p>
              This course is suitable for both novice operators and experienced
              individuals looking to formalize their qualifications. Training
              duration will depend on prior experience and competency levels.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the construction industry and the key
                responsibilities of a plant operator in ensuring workplace
                safety.
              </li>
              <li>
                Identify the main components and controls of a 180º excavator,
                including terminology and function of each part.
              </li>
              <li>
                Perform thorough pre-use checks, routine maintenance, and fault
                detection to ensure operational efficiency.
              </li>
              <li>
                Comply with manufacturer guidelines, health and safety
                regulations, and industry best practices.
              </li>
              <li>
                Prepare the machine for both on-site operation and highway
                travel, ensuring safe maneuverability in various terrains.
              </li>
              <li>
                Navigate rough, uneven ground and inclines while maintaining
                machine stability and control.
              </li>
              <li>
                Set up the excavator for digging tasks, including positioning
                for optimal efficiency and awareness of underground services.
              </li>
              <li>
                Excavate different types of ground, manage material handling,
                and optimize digging techniques for productivity.
              </li>
              <li>
                Understand load handling principles, weight distribution, and
                the impact of external factors on machine stability.
              </li>
              <li>
                Implement proper shut-down procedures, secure the machine, and
                prepare it for transportation when required.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              This course includes a combination of theoretical instruction and
              hands-on practical training. The assessment process consists of:
            </p>
            <br />
            <ul>
              <li>
                A theory test covering safety regulations, machine components,
                and operational principles.
              </li>
              <li>
                A practical test where candidates demonstrate their ability to
                operate the excavator effectively, including pre-use checks,
                maneuvering, excavation techniques, and load handling.
              </li>
            </ul>
            <br />
            <p>
              The final assessment will be conducted as per CPCS technical test
              requirements to evaluate competency.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Candidates who successfully pass the CPCS technical tests will be
              awarded the CPCS Red Trained Operator Card, valid for two years.
              This certification serves as proof of competency in operating
              Excavators 180º below 5 tonnes.
            </p>
            <br />
            <p>
              To advance to the CPCS Blue Competence Card, operators must
              complete an NVQ Level 2 in Plant Operations within the two-year
              validity period of the Red Card.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A10 CPCS Excavator 180º Below 5 Tonnes course delivers
              comprehensive training to ensure operators gain the necessary
              expertise to work safely and efficiently. The structured program
              includes both theoretical knowledge and hands-on experience,
              preparing participants for real-world excavation tasks.
            </p>
            <br />
            <p>
              This course is essential for individuals working in construction,
              utilities, and groundworks, where excavators play a critical role
              in site operations.
            </p>
            <br />
            <p>
              Enroll today to gain an industry-recognized certification and take
              the next step in your career within the construction and plant
              operation industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
