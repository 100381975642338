import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSCrawlerTractorDozer() {
  return (
    <>
      <Helmet>
        <title>A34 Crawler Tractor / Dozer | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Crawler Tractor / Dozer A34 training course at Alpha Construction Training to gain essential skills and certification for operating crawler dozers."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A34 Crawler Tractor / Dozer</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Crawler Tractor / Dozer A34 Training Course is designed
              to equip participants with the technical knowledge and practical
              skills required for the safe and efficient operation of crawler
              dozers. The course caters to both newcomers and experienced
              operators looking to refine their abilities or gain official CPCS
              certification.
            </p>
            <br />
            <p>
              Participants will learn how to operate crawler dozers effectively
              in various construction environments, including earthmoving,
              grading, and site preparation. Emphasis is placed on safety
              procedures, equipment maintenance, and compliance with industry
              regulations to ensure a high standard of competency in real-world
              operations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the responsibilities and duties of a plant operator,
                including legal obligations and industry best practices.
              </li>
              <li>
                Gain familiarity with the key components, mechanical systems,
                and operational controls of crawler dozers.
              </li>
              <li>
                Perform thorough pre-use, operational, and post-use safety
                checks, ensuring compliance with manufacturer guidelines and
                regulations.
              </li>
              <li>
                Learn how to correctly configure the crawler dozer for various
                site tasks, including excavation, grading, and material
                handling.
              </li>
              <li>
                Develop techniques for safe maneuvering over different types of
                terrain, including slopes, uneven ground, and confined spaces.
              </li>
              <li>
                Master excavation tasks such as trenching, cutting, and
                backfilling, while ensuring accuracy and efficiency.
              </li>
              <li>
                Understand advanced grading techniques for site leveling,
                spreading, and stockpile management.
              </li>
              <li>
                Identify potential hazards, including underground and overhead
                services, and implement strategies for risk mitigation.
              </li>
              <li>
                Learn proper shut-down and securing procedures to maintain
                machine safety and longevity.
              </li>
              <li>
                Understand best practices for loading and unloading crawler
                dozers for transport, ensuring legal compliance and safe
                handling.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course includes a combination of classroom-based instruction
              and hands-on practical exercises. Candidates will be assessed
              through a series of evaluations designed to measure their
              operational skills and theoretical knowledge.
            </p>
            <br />
            <p>
              The CPCS technical test includes a practical assessment where
              participants must demonstrate safe and efficient machine handling,
              as well as a verbal or written test covering theoretical
              principles, site safety, and best practices.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and technical assessment,
              candidates will receive a Red CPCS Trained Operator Card, which is
              valid for two years. During this period, operators must complete
              an NVQ Level 2 in Plant Operations to upgrade to the Blue CPCS
              Competence Card.
            </p>
            <br />
            <p>
              The Blue CPCS Competence Card is renewable every five years and
              demonstrates the operator’s continued proficiency and compliance
              with industry standards.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Crawler Tractor / Dozer A34 Training Course is an
              essential program for anyone looking to enhance their career in
              plant operations. Through a combination of theoretical lessons and
              hands-on training, participants gain valuable expertise in machine
              control, excavation techniques, and safe working practices.
            </p>
            <br />
            <p>
              This course is ideal for new entrants into the construction
              industry as well as experienced operators seeking formal
              certification. By obtaining a CPCS qualification, learners improve
              their employability and ensure they meet industry safety
              standards.
            </p>
            <br />
            <p>
              Enroll today to take the next step in your construction career and
              gain the certification needed to operate crawler dozers safely and
              efficiently.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
