import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSCrawlerSideBoom() {
  return (
    <>
      <Helmet>
        <title>
          A35 Crawler Tractor / Side Boom | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join the CPCS Crawler Tractor / Side Boom A35 training course at Alpha Construction Training to gain essential skills and certification for operating crawler tractors and side booms."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A35 Crawler Tractor / Side Boom</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Crawler Tractor / Side Boom A35 Training Course is
              designed to provide participants with the technical knowledge and
              practical skills required to operate crawler tractors and side
              booms safely and effectively. This course caters to individuals
              entering the industry, as well as experienced operators looking to
              refresh or formalize their skills with CPCS certification.
            </p>
            <br />
            <p>
              Participants will learn to maneuver these machines across various
              terrains, execute safe lifting and handling operations, and follow
              industry best practices. By the end of the course, learners will
              be competent in configuring, operating, and maintaining crawler
              tractors with side booms in compliance with health and safety
              regulations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the responsibilities of a plant operator and adhere
                to industry regulations, including health and safety guidelines.
              </li>
              <li>
                Gain in-depth knowledge of crawler tractor components,
                mechanical systems, and side boom attachments, including their
                functions and limitations.
              </li>
              <li>
                Conduct thorough pre-start, operational, and post-use checks to
                ensure the machine is safe and ready for use.
              </li>
              <li>
                Learn how to properly configure the machine for transport,
                operational tasks, and lifting procedures.
              </li>
              <li>
                Develop skills to safely maneuver and operate the crawler
                tractor over a variety of terrains, including inclines, uneven
                surfaces, and confined spaces.
              </li>
              <li>
                Master safe lifting techniques using the side boom, including
                handling different types of loads, weight distribution, and
                secure positioning.
              </li>
              <li>
                Identify potential hazards, underground and overhead
                obstructions, and implement control measures to mitigate risks
                on-site.
              </li>
              <li>
                Follow proper shut-down, securing, and maintenance procedures to
                prolong machine lifespan and ensure safety.
              </li>
              <li>
                Understand best practices for loading and unloading crawler
                tractors for transport, ensuring compliance with legal
                requirements.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course assessment consists of both theoretical and practical
              evaluations. Participants will be required to demonstrate their
              understanding of safety regulations, machine operations, and best
              practices through a written exam. The practical test will assess
              competency in handling the crawler tractor and side boom in
              real-world scenarios, including lifting, maneuvering, and securing
              loads.
            </p>
            <br />
            <p>
              Candidates must pass the CPCS technical test, which includes a
              practical examination and a verbal questioning segment, to receive
              certification.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and technical test,
              participants will receive a Red CPCS Trained Operator Card, which
              remains valid for two years. During this period, operators must
              complete an NVQ Level 2 in Plant Operations to qualify for the
              Blue CPCS Competence Card.
            </p>
            <br />
            <p>
              The Blue CPCS Competence Card is renewable every five years
              through refresher training, ensuring that operators stay up to
              date with industry standards and safety regulations.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Crawler Tractor / Side Boom A35 Training Course offers a
              structured pathway to mastering the operation of crawler tractors
              equipped with side booms. Through a blend of theoretical learning
              and hands-on training, participants gain a deep understanding of
              safety procedures, machine handling, and efficient lifting
              practices.
            </p>
            <br />
            <p>
              This course is essential for individuals looking to enter the
              plant operations industry or experienced operators seeking to
              upgrade their skills and gain CPCS certification. By completing
              this program, learners enhance their employability and ensure
              compliance with industry safety standards.
            </p>
            <br />
            <p>
              Enroll today to gain the expertise and certification needed to
              advance your career in plant operations and lifting services.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
