import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSMotorisedScraper() {
  return (
    <>
      <Helmet>
        <title>A24 Motorised Scraper | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the A24 CPCS Motorised Scraper training course at Alpha Construction Training and become a certified operator in motorised scraper machinery."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A24 Motorised Scraper</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A24 Motorised Scraper course is designed to provide
              participants with the knowledge and practical skills required to
              safely operate a motorised scraper in various construction and
              earthmoving environments. This course covers essential topics such
              as machine functionality, operating techniques, site safety, and
              hazard awareness.
            </p>
            <br />
            <p>
              Whether you are a novice seeking to enter the industry or an
              experienced operator looking to obtain formal certification, this
              course ensures that you can confidently and efficiently handle
              motorised scraper operations in compliance with industry
              standards.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Identify and explain the purpose of key components, controls,
                and attachments of a motorised scraper.
              </li>
              <li>
                Conduct pre-use inspections and maintenance checks to ensure
                machine safety and efficiency.
              </li>
              <li>
                Understand and follow the manufacturer’s guidelines and industry
                regulations for safe operation.
              </li>
              <li>
                Configure the machine for site travel, including maneuvering
                over level ground, inclines, and rough terrain.
              </li>
              <li>
                Develop skills for loading, carrying, and unloading materials
                effectively while maintaining stability.
              </li>
              <li>
                Learn the correct techniques for scraping, spreading, and
                leveling material in various site conditions.
              </li>
              <li>
                Identify potential hazards, including underground services,
                overhead obstructions, and changing ground conditions.
              </li>
              <li>
                Follow procedures for backfilling, spreading, and site
                preparation tasks.
              </li>
              <li>
                Execute safe shutdown, parking, and securing procedures after
                operation.
              </li>
              <li>
                Apply best practices for workplace safety, environmental
                considerations, and risk management.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment process consists of both theoretical and practical
              evaluations. Candidates will undertake a written examination
              covering key concepts, safety regulations, and machine operations.
            </p>
            <br />
            <p>
              The practical assessment will require candidates to demonstrate
              their ability to conduct pre-use checks, operate the machine
              efficiently, manage site travel, and complete a series of tasks
              related to material loading, scraping, and unloading.
            </p>
            <br />
            <p>
              Assessments are conducted under CPCS technical test conditions to
              ensure compliance with industry standards.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the CPCS A24 Motorised Scraper
              course, participants will receive a Red CPCS Trained Operator
              Card, valid for two years. This certification is a requirement for
              individuals operating motorised scrapers on construction sites.
            </p>
            <br />
            <p>
              To upgrade to a Blue CPCS Competence Card, candidates must
              complete an NVQ Level 2 in Plant Operations within the two-year
              validity period. The Blue CPCS Card is recognized as proof of
              competence for experienced operators.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A24 Motorised Scraper course provides a structured learning
              experience that combines classroom-based theory with hands-on
              practical training. By completing this course, participants will
              gain a thorough understanding of motorised scraper operation,
              safety protocols, and industry best practices.
            </p>
            <br />
            <p>
              This course is suitable for individuals looking to develop their
              skills in earthmoving operations, whether they are new to the
              industry or seeking formal recognition of their experience.
            </p>
            <br />
            <p>
              Enroll today to obtain your CPCS certification and take the next
              step in your construction career.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
