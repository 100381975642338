import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSLoaderCompressor() {
  return (
    <>
      <Helmet>
        <title>A41 Loader Compressor | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Loader Compressor A41 training course at Alpha Construction Training to gain essential skills for operating loader compressors safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A41 Loader Compressor</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The Loader Compressor A41 CPCS training course is designed to
              provide participants with a comprehensive understanding of loader
              compressor operations. The course covers both theoretical
              principles and practical applications, ensuring that operators can
              safely and efficiently handle the machinery in different site
              conditions. Safety compliance, machine maintenance, and effective
              operation techniques are key areas of focus.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Gain an in-depth understanding of loader compressor components,
                attachments, and their functions in construction environments.
              </li>
              <li>
                Conduct thorough pre-use checks, maintenance procedures, and
                diagnostic assessments to ensure machine reliability and safety.
              </li>
              <li>
                Operate the loader compressor with precision across different
                site conditions, including excavation, material transportation,
                and air-powered tool applications.
              </li>
              <li>
                Implement industry-standard safety measures, including hazard
                recognition and risk assessment strategies.
              </li>
              <li>
                Understand the procedures for correctly shutting down and
                securing loader compressors after use.
              </li>
              <li>
                Learn how to troubleshoot common operational challenges such as
                air pressure inconsistencies and mechanical faults.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course consists of both theoretical and practical assessments.
              Candidates will be evaluated through written tests covering safety
              protocols, machine mechanics, and operational procedures.
              Practical assessments will include supervised operation of a
              loader compressor, where participants must demonstrate competence
              in handling and maintaining the machine according to industry
              regulations. The final CPCS technical test determines overall
              certification eligibility.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive a Red CPCS Trained Operator
              Card, valid for two years. To upgrade to a Blue CPCS Competence
              Card, participants must complete an NVQ Level 2 in Plant
              Operations within this period. The Blue Card is renewable every
              five years, ensuring continued compliance with industry standards
              and professional development.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Loader Compressor A41 course provides a structured
              training program that emphasizes safety, efficiency, and proper
              machine handling. Whether you are new to loader compressor
              operations or looking to refine your skills, this course will
              equip you with the necessary expertise to work confidently in
              construction environments.
            </p>
            <br />
            <p>
              With a combination of classroom instruction and hands-on
              experience, participants will develop essential skills that
              enhance workplace safety and operational effectiveness.
            </p>
            <br />
            <p>
              By completing this course, candidates will improve their
              employability, gain industry-recognized certification, and take
              the first step toward career advancement in the construction and
              plant operations sector.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
