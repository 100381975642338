import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSHoist() {
  return (
    <>
      <Helmet>
        <title>A20 Hoist | Alpha Construction Training</title>
        <meta
          name="description"
          content="Gain formal qualifications with the A20 CPCS Hoist training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A20 Hoist</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A20 Hoist course is designed to provide participants with the
              skills and knowledge required to safely operate a hoist. Whether
              you are new to hoist operation or seeking to formalize your
              experience, this course ensures you gain the necessary
              qualifications to work safely and effectively on construction
              sites.
            </p>
            <br />
            <p>
              This course includes a thorough analysis of hoist operations,
              safety checks, maintenance procedures, and emergency protocols.
              Through both theoretical and practical training, candidates will
              gain competency in hoist operation, ensuring safety and
              operational efficiency.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the nature of the hoist sector and the
                responsibilities of an operator in maintaining site safety.
              </li>
              <li>
                Identify various types of hoists, their components, functions,
                and key terminologies.
              </li>
              <li>
                Interpret and follow manufacturer guidelines, manuals, and
                relevant safety regulations.
              </li>
              <li>
                Assess hoist limitations and ensure all pre-use checks are
                thoroughly conducted before operation.
              </li>
              <li>
                Inspect gates, safety devices, and hoist functionality during
                running checks to ensure readiness.
              </li>
              <li>
                Ensure hoist stability, system alignment, and effective
                hoist-way protection for safe operation.
              </li>
              <li>
                Calculate hoist capacity and verify that it can safely
                accommodate required loads or passengers.
              </li>
              <li>
                Execute emergency lowering and evacuation procedures in
                compliance with safety protocols (Endorsements B & D).
              </li>
              <li>
                Safely prepare the hoist for various load types, ensuring
                suitability for transport.
              </li>
              <li>
                Operate the hoist to its full travel range and across different
                landing levels, both loaded and unloaded, in a controlled and
                efficient manner.
              </li>
              <li>
                Communicate clearly with colleagues and follow site safety
                procedures through signals, instructions, and protocols.
              </li>
              <li>
                Safely secure the hoist, placing it in an out-of-service
                condition and isolating it after use.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The A20 Hoist course includes both classroom-based theoretical
              instruction and practical exercises. After the training,
              candidates must complete a one-day assessment, which includes
              technical tests that cover hoist operation, safety checks, and
              emergency procedures.
            </p>
            <br />
            <p>
              The practical assessment requires candidates to demonstrate their
              competency in operating a hoist safely, while the theoretical test
              ensures they understand key safety regulations and operational
              protocols.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the technical tests, learners will
              receive a Red CPCS Trained Operator Card, valid for two years.
              This card confirms the participant's ability to safely operate a
              hoist in compliance with industry regulations.
            </p>
            <br />
            <p>
              To advance their career and move to a Blue CPCS Competence Card,
              participants must complete an NVQ Level 2 in Plant Operations
              within the two-year validity period of their Red Card. The Blue
              Card demonstrates full occupational competence and enhances
              employability within the construction industry.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A20 Hoist course offers a comprehensive training experience
              that combines classroom learning with hands-on practical sessions.
              This ensures participants are well-prepared to operate hoists
              safely and efficiently on construction sites.
            </p>
            <br />
            <p>
              The course is suitable for both new operators and those seeking to
              formalize their existing experience with a recognized
              qualification. Participants will gain the skills needed to meet
              industry standards and ensure a safe working environment.
            </p>
            <br />
            <p>
              Enroll today to gain your CPCS certification and take your career
              to the next level in hoist operation and construction site
              management.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
