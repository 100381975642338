import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSRoughTerrainForklift() {
  return (
    <>
      <Helmet>
        <title>A14 Rough Terrain Forklift | Alpha Construction Training</title>
        <meta
          name="description"
          content="Learn to operate Rough Terrain Forklifts safely and efficiently with the A14 CPCS Rough Terrain Forklift training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A14 Rough Terrain Forklift</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A14 Rough Terrain Forklift course is designed to provide
              operators with the essential skills and knowledge required for the
              safe and efficient use of rough terrain forklifts in a variety of
              working environments. This course ensures compliance with industry
              safety standards and operational best practices.
            </p>
            <br />
            <p>
              Training duration varies based on individual experience,
              accommodating both novice learners and experienced operators
              seeking formal certification.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the roles and responsibilities of a rough terrain
                forklift operator, including compliance with health and safety
                regulations.
              </li>
              <li>
                Identify the key components, controls, and safety features of
                the machine.
              </li>
              <li>
                Conduct thorough pre-use inspections and routine servicing
                procedures to ensure operational safety.
              </li>
              <li>
                Configure and set up the machine for travel, including safe
                maneuvering in challenging terrains.
              </li>
              <li>
                Operate the forklift efficiently to lift, transport, and
                position loads while maintaining stability.
              </li>
              <li>
                Recognize the risks associated with quick hitch devices and
                understand their safe operation.
              </li>
              <li>
                Use ancillary equipment and attachments correctly for various
                site tasks.
              </li>
              <li>
                Understand the machine’s capabilities, limitations, and correct
                usage in different site conditions.
              </li>
              <li>
                Safely prepare the machine for travel on public roads, including
                securing loads and complying with legal requirements.
              </li>
              <li>
                Implement proper shut-down and securing procedures at the end of
                each work period.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course combines theoretical instruction with practical
              exercises to ensure participants develop competency in rough
              terrain forklift operation. The final assessment consists of CPCS
              technical tests, which include a written theory test and a
              practical evaluation.
            </p>
            <br />
            <p>
              Candidates must demonstrate their ability to operate the machine
              safely, conduct pre-use checks, and complete lifting and
              maneuvering tasks effectively.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the CPCS technical tests,
              participants will receive a Red CPCS Trained Operator Card, which
              is valid for two years. This serves as formal recognition of
              training and competency in operating a rough terrain forklift.
            </p>
            <br />
            <p>
              To upgrade to a Blue CPCS Competence Card, candidates must
              complete an NVQ Level 2 in Plant Operations within the two-year
              validity period of their Red Card.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A14 Rough Terrain Forklift course equips participants with the
              necessary skills to operate rough terrain forklifts safely and
              efficiently in demanding work environments. It provides a
              structured blend of theoretical learning and hands-on experience,
              ensuring compliance with industry standards.
            </p>
            <br />
            <p>
              This course is ideal for individuals working in construction,
              agriculture, warehousing, and logistics, where rough terrain
              forklifts are essential for material handling on uneven surfaces.
            </p>
            <br />
            <p>
              Enroll today to gain your CPCS certification and enhance your
              career prospects in plant operations and material handling.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
