import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTrackedLoadingShovel() {
  return (
    <>
      <Helmet>
        <title>A22 Tracked Loading Shovel | Alpha Construction Training</title>
        <meta
          name="description"
          content="Enroll in the A22 CPCS Tracked Loading Shovel training course at Alpha Construction Training and gain industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A22 Tracked Loading Shovel</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A22 Tracked Loading Shovel course is designed to equip
              operators with the necessary knowledge and practical skills to
              operate a tracked loading shovel safely and efficiently. This
              course is suitable for both novice and experienced operators who
              require formal certification to meet industry safety and
              operational standards.
            </p>
            <br />
            <p>
              The training covers machine controls, loading techniques, hazard
              awareness, and best practices to ensure safe and effective machine
              operation in a range of site conditions.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Identify and explain the functions of key components, controls,
                and attachments of a tracked loading shovel.
              </li>
              <li>
                Conduct pre-use inspections, routine maintenance, and safety
                checks to ensure the machine is in proper working condition.
              </li>
              <li>
                Understand the importance of adhering to manufacturer guidelines
                and relevant health and safety regulations.
              </li>
              <li>
                Develop proficiency in maneuvering and operating the tracked
                loading shovel over different terrains, including uneven and
                sloped surfaces.
              </li>
              <li>
                Learn effective material handling techniques, including loading,
                unloading, and stockpiling materials safely and efficiently.
              </li>
              <li>
                Recognize and mitigate potential site hazards, including
                underground utilities, overhead obstructions, and pedestrian
                traffic.
              </li>
              <li>
                Operate the machine in confined spaces while maintaining control
                and stability.
              </li>
              <li>
                Follow proper lifting, positioning, and dumping techniques to
                maximize operational efficiency.
              </li>
              <li>
                Perform machine shutdown, parking, and securing procedures to
                prevent accidents and equipment damage.
              </li>
              <li>
                Understand correct loading and unloading procedures when
                transporting the machine between sites.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Candidates will be assessed through both theoretical and practical
              evaluations. The theoretical assessment consists of a written test
              covering essential safety regulations, operational principles, and
              best practices for tracked loading shovel operation.
            </p>
            <br />
            <p>
              The practical assessment requires candidates to demonstrate
              competency in operating the machine, completing material handling
              tasks, and following industry safety procedures.
            </p>
            <br />
            <p>
              All assessments are conducted under CPCS technical test
              conditions, ensuring candidates meet the required industry
              competency standards.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully passing the CPCS technical test, candidates will
              receive a Red CPCS Trained Operator Card, which remains valid for
              two years. This certification demonstrates the holder's ability to
              operate a tracked loading shovel safely and effectively.
            </p>
            <br />
            <p>
              To upgrade to a Blue CPCS Competence Card, candidates must
              complete an NVQ Level 2 in Plant Operations within the two-year
              validity period of their Red Card. The Blue CPCS Card serves as
              proof of full occupational competence for experienced operators.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A22 Tracked Loading Shovel course provides comprehensive
              training to prepare candidates for the challenges of operating a
              tracked loading shovel in a construction or industrial
              environment. Through a combination of classroom instruction and
              hands-on practical training, participants will develop the skills
              and confidence needed to operate this machinery efficiently.
            </p>
            <br />
            <p>
              Whether you are starting your career in construction plant
              operations or seeking to formalize your experience with
              certification, this course will provide the necessary training to
              help you succeed.
            </p>
            <br />
            <p>
              Enroll today to gain your CPCS certification and enhance your
              career prospects as a tracked loading shovel operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
