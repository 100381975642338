import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTrencher() {
  return (
    <>
      <Helmet>
        <title>A37 Trencher | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Trencher A37 training course at Alpha Construction Training to gain essential skills and certification for operating trenchers."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A37 Trencher</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Trencher A37 training course is designed to provide
              participants with the fundamental knowledge and hands-on
              experience necessary to safely and efficiently operate trenchers
              in various work environments. Trenchers are essential for
              excavation tasks, including laying cables, pipelines, and drainage
              systems, making them a crucial piece of equipment in the
              construction and utility industries.
            </p>
            <br />
            <p>
              This course is tailored for both newcomers seeking to learn the
              basics of trencher operation and experienced operators looking to
              refine their skills or gain formal certification. By completing
              this course, candidates will meet industry standards and be fully
              prepared for CPCS certification.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Gain a comprehensive understanding of trencher components,
                functions, and operational mechanisms.
              </li>
              <li>
                Learn essential pre-start and post-operation checks, maintenance
                procedures, and fault identification to ensure equipment
                longevity.
              </li>
              <li>
                Develop skills to operate trenchers on a variety of terrains,
                including sloped and uneven ground, while maintaining stability
                and precision.
              </li>
              <li>
                Master the correct procedures for setting up the trencher,
                adjusting trench depth, and ensuring proper alignment for
                excavation tasks.
              </li>
              <li>
                Learn advanced trenching techniques for different applications,
                including pipe laying, drainage work, and cable installation.
              </li>
              <li>
                Understand best practices for maneuvering trenchers in confined
                spaces and avoiding obstacles while maintaining safety.
              </li>
              <li>
                Gain knowledge of underground and overhead hazards, including
                identifying and working safely around buried services and
                utility lines.
              </li>
              <li>
                Learn how to safely shut down, park, and secure the trencher
                after use to prevent accidents or unauthorized operation.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a combination of theoretical instruction and
              hands-on practical training, ensuring that candidates develop both
              technical knowledge and operational competence.
            </p>
            <br />
            <p>
              Assessment is conducted through the CPCS technical test, which
              consists of both a written theory examination and a practical
              skills test. The practical test will assess the candidate’s
              ability to safely operate the trencher, perform necessary
              adjustments, and navigate different working conditions
              effectively.
            </p>
            <br />
            <p>
              Candidates must demonstrate proficiency in all areas of operation,
              including pre-use inspections, hazard awareness, trenching
              techniques, and shutdown procedures.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully passing the CPCS technical test, participants
              will receive a Red CPCS Trained Operator Card, which is valid for
              two years. This card demonstrates that the holder has completed
              formal training and met CPCS standards for trencher operation.
            </p>
            <br />
            <p>
              To progress to a Blue CPCS Competence Card, candidates must
              complete an NVQ Level 2 in Plant Operations within the two-year
              validity period. The Blue Card remains valid for five years and
              requires periodic renewal through refresher training and
              competency reassessment.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Trencher A37 training course provides a comprehensive
              introduction to trencher operation, covering both theoretical
              knowledge and practical hands-on experience. Participants will
              gain the skills needed to operate a trencher safely and
              efficiently, ensuring compliance with industry regulations and
              best practices.
            </p>
            <br />
            <p>
              This course is essential for individuals pursuing careers in
              excavation, utility installation, and general construction work.
              By successfully completing the training, candidates will enhance
              their job prospects, improve workplace safety, and meet
              industry-recognized certification standards.
            </p>
            <br />
            <p>
              Enroll today to develop your expertise in trencher operation and
              take the next step in your construction career with CPCS
              certification.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
