import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPlantMarshaller() {
  return (
    <>
      <Helmet>
        <title>A73 Plant and Vehicle Marshaller</title>
        <meta
          name="description"
          content="Develop the skills and knowledge needed to guide plant and vehicles safely with the Plant and Vehicle Marshaller (A73) course at Alpha Construction Training. Achieve CPCS certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A73 Plant and Vehicle Marshaller</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A73 Plant and Vehicle Marshaller course is designed to equip
              participants with the essential skills required to guide and
              control the movement of vehicles and plant machinery safely. The
              training focuses on effective communication techniques, traffic
              management procedures, and hazard awareness to ensure smooth
              operations on construction sites.
            </p>
            <br />
            <p>
              Participants will develop a thorough understanding of site
              logistics, marshalling responsibilities, and best practices to
              enhance safety and efficiency in plant and vehicle movement.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Develop a strong understanding of traffic management strategies
                and site-specific safety regulations.
              </li>
              <li>
                Identify common hazards associated with moving vehicles and
                plant equipment and learn methods to minimize risks.
              </li>
              <li>
                Master the use of industry-standard hand signals and
                communication devices such as radios to guide vehicle operators
                effectively.
              </li>
              <li>
                Understand legal requirements, including health and safety laws
                and manufacturer guidelines, for operating within a controlled
                environment.
              </li>
              <li>
                Conduct thorough pre-use inspections of marshalling equipment to
                ensure operational readiness and compliance.
              </li>
              <li>
                Apply proper signalling methods during reversing, maneuvering,
                loading, and offloading operations in diverse site conditions.
              </li>
              <li>
                Work safely in high-risk areas, including environments with
                underground services, overhead obstacles, and confined spaces.
              </li>
              <li>
                Coordinate effectively with site personnel, machinery operators,
                and traffic management teams to streamline vehicle movements.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              The assessment process includes both theoretical and practical
              evaluations to ensure participants have a comprehensive
              understanding of marshalling procedures and safety compliance.
            </p>
            <br />
            <ul>
              <li>
                A written or verbal knowledge test covering traffic management,
                hazard recognition, and regulatory compliance.
              </li>
              <li>
                A hands-on practical assessment where participants demonstrate
                their ability to safely marshal vehicles, use proper hand
                signals, and manage site risks effectively.
              </li>
            </ul>
            <br />
            <p>
              The final CPCS technical test will be conducted to verify
              competency before certification is issued.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and CPCS technical test,
              participants will receive the CPCS Red Trained Operator Card for
              Plant and Vehicle Marshaller (A73). This certification is valid
              for two years and serves as an entry-level qualification for
              individuals in the construction and logistics industries.
            </p>
            <br />
            <p>
              To further enhance their credentials, operators can complete an
              NVQ Level 2 in Plant Operations, which qualifies them for the CPCS
              Blue Competence Card, a long-term industry-recognized
              certification.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A73 Plant and Vehicle Marshaller course provides a
              comprehensive learning experience that combines theoretical
              instruction with practical exercises. Participants will gain
              hands-on experience in guiding vehicles, identifying hazards, and
              managing site traffic to ensure safe and efficient construction
              operations.
            </p>
            <br />
            <p>
              This training is ideal for those seeking to work in construction,
              civil engineering, logistics, and plant operation industries where
              vehicle and machinery movement plays a crucial role.
            </p>
            <br />
            <p>
              Enroll today to obtain industry-recognized certification and gain
              the expertise required to enhance workplace safety and improve
              career prospects in plant and vehicle marshalling.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
