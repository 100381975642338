import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSWheeledLoadingShovel() {
  return (
    <>
      <Helmet>
        <title>A21 Wheeled Loading Shovel | Alpha Construction Training</title>
        <meta
          name="description"
          content="Gain formal qualifications with the A21 CPCS Wheeled Loading Shovel training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A21 Wheeled Loading Shovel</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A21 Wheeled Loading Shovel course is designed to provide
              participants with the necessary knowledge and practical skills to
              operate a wheeled loading shovel safely and efficiently. This
              course caters to both novice and experienced operators who require
              formal certification to meet industry safety and operational
              standards.
            </p>
            <br />
            <p>
              Training includes machine controls, hazard awareness, material
              handling techniques, and best practices for safe site operations.
              By the end of the course, learners will be fully prepared to
              operate a wheeled loading shovel in compliance with industry
              regulations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Identify key components, controls, and functions of a wheeled
                loading shovel.
              </li>
              <li>
                Perform pre-use inspections, routine maintenance, and safety
                checks to ensure operational efficiency.
              </li>
              <li>
                Understand and apply health and safety regulations relevant to
                wheeled loading shovel operations.
              </li>
              <li>
                Conduct safe mounting, dismounting, and parking procedures.
              </li>
              <li>
                Operate the machine safely on different terrains, including
                slopes and confined spaces.
              </li>
              <li>
                Execute proper loading, unloading, and stockpiling techniques to
                maximize productivity.
              </li>
              <li>
                Identify and mitigate potential hazards, including underground
                utilities, overhead obstructions, and nearby workers.
              </li>
              <li>
                Follow best practices for material excavation from embankments
                and stockpile formation.
              </li>
              <li>
                Secure the machine in an out-of-service condition to prevent
                unauthorized use or accidents.
              </li>
              <li>
                Understand correct transportation procedures, including loading
                and unloading the machine for transit.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Candidates will be evaluated through a combination of theoretical
              and practical assessments. The theoretical assessment consists of
              a written test covering essential safety protocols, operational
              procedures, and industry regulations.
            </p>
            <br />
            <p>
              The practical assessment requires candidates to demonstrate
              competency in handling the machine, completing material loading
              tasks, and adhering to safety guidelines.
            </p>
            <br />
            <p>
              All assessments are conducted in line with CPCS technical test
              requirements to ensure operators meet the necessary industry
              standards.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully completing the CPCS technical test, candidates
              will receive a Red CPCS Trained Operator Card, which remains valid
              for two years. This certification verifies the operator’s ability
              to safely operate a wheeled loading shovel.
            </p>
            <br />
            <p>
              To progress to a Blue CPCS Competence Card, candidates must
              complete an NVQ Level 2 in Plant Operations within the two-year
              validity period of their Red Card. The Blue Card confirms full
              occupational competency and enhances career prospects in the
              construction industry.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A21 Wheeled Loading Shovel course provides in-depth training
              to ensure candidates are well-equipped with the skills and
              knowledge needed for safe and effective machine operation. By
              combining theoretical instruction with practical experience, this
              course prepares participants for certification and career
              advancement.
            </p>
            <br />
            <p>
              Whether you are new to construction plant operations or looking to
              formalize your experience with a recognized qualification, this
              course will help you develop essential skills and boost your
              employment opportunities.
            </p>
            <br />
            <p>
              Enroll today to gain your CPCS certification and take the next
              step in your career as a skilled wheeled loading shovel operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
