import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSScreener() {
  return (
    <>
      <Helmet>
        <title>A43 Screener | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Screener A43 training course at Alpha Construction Training to gain essential skills for operating screeners safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A43 Screener</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The Screener A43 CPCS training course is designed to provide
              operators with the essential knowledge and hands-on experience
              needed to operate screeners safely and efficiently. Participants
              will learn how to process raw materials effectively, understand
              operational controls, and ensure workplace safety while using the
              machine in different site conditions. The course emphasizes
              compliance with industry standards, ensuring that operators can
              handle screeners in a professional manner while reducing risks and
              improving productivity.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Gain an understanding of the construction and aggregates sector,
                including the role of screeners in material processing and site
                operations.
              </li>
              <li>
                Identify and describe the principal components of a screener,
                including engine, conveyor belts, screen decks, and control
                systems.
              </li>
              <li>
                Comply with legal and safety regulations, including the Health
                and Safety at Work Act, PUWER, and relevant manufacturer
                guidelines.
              </li>
              <li>
                Perform pre-start inspections, identifying any mechanical faults
                or safety concerns before commencing operations.
              </li>
              <li>
                Set up and adjust the screener for different material types,
                ensuring correct positioning for maximum efficiency and output
                quality.
              </li>
              <li>
                Operate the screener safely, ensuring correct material feeding,
                sorting, and processing for various grading specifications.
              </li>
              <li>
                Manage throughput rates and workflow, preventing material
                overload, blockages, and excessive wear on machinery components.
              </li>
              <li>
                Utilize appropriate hand signals and verbal communication to
                coordinate operations with site personnel and loading equipment
                operators.
              </li>
              <li>
                Identify and manage hazards, including dust control, vibration
                risks, and environmental considerations related to material
                screening.
              </li>
              <li>
                Carry out proper shutdown procedures, securing the machine
                safely and performing post-use maintenance checks.
              </li>
              <li>
                Understand the correct methods for loading, transporting, and
                setting up screeners on different work sites.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment process consists of both theoretical and practical
              components. Candidates will undergo classroom-based training
              covering health and safety regulations, equipment controls, and
              best operating practices. The practical assessment includes
              demonstrating competence in operating a screener, following safety
              procedures, and managing materials effectively. The course
              concludes with the CPCS technical test, which evaluates the
              candidate’s understanding and operational skills to ensure they
              meet industry requirements.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course, candidates will receive
              the CPCS Red Trained Operator Card, which is valid for two years.
              This card serves as proof of initial competency in screener
              operation. To progress further, candidates must complete an NVQ
              Level 2 in Plant Operations within the validity period to upgrade
              to the CPCS Blue Competence Card. The Blue Card is valid for five
              years and can be renewed by demonstrating continued professional
              competence and industry experience.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Screener A43 course provides in-depth training on the
              efficient and safe operation of screeners. This course is ideal
              for both novice and experienced operators who want to enhance
              their knowledge and gain a recognized qualification. Participants
              will gain hands-on experience in configuring, operating, and
              maintaining screeners, ensuring compliance with site safety
              standards and productivity targets.
            </p>
            <br />
            <p>
              Completing this course will not only improve employability but
              also open up new career opportunities in construction, quarrying,
              and material processing industries. Operators with CPCS
              certification are highly sought after, as they demonstrate
              professional competence and adherence to industry best practices.
            </p>
            <br />
            <p>
              Enroll today to develop essential screening skills and achieve a
              competitive edge in the construction and aggregates sector. Take
              the next step toward becoming a certified and skilled screener
              operator with Alpha Construction Training.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
