import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSkidSteerLoader() {
  return (
    <>
      <Helmet>
        <title>A23 Skid Steer Loader | Alpha Construction Training</title>
        <meta
          name="description"
          content="Enroll in the A23 CPCS Skid Steer Loader training course at Alpha Construction Training and gain industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A23 Skid Steer Loader</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A23 Skid Steer Loader training course is designed to provide
              learners with the essential knowledge and practical skills needed
              to operate a skid steer loader safely and efficiently. This course
              is suitable for both novice and experienced operators who require
              formal certification in compliance with industry regulations.
            </p>
            <br />
            <p>
              The training will cover key operational procedures, safety
              guidelines, and best practices to ensure candidates gain a
              comprehensive understanding of the machine’s functions and
              responsibilities when operating on-site.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Identify and explain the functions of key components, controls,
                and attachments of a skid steer loader.
              </li>
              <li>
                Understand the basic construction and operating principles of
                the machine.
              </li>
              <li>
                Follow manufacturer guidelines, industry regulations, and
                relevant legislation to ensure safe operation.
              </li>
              <li>
                Conduct pre-use checks and routine maintenance to ensure the
                machine is in safe working condition.
              </li>
              <li>
                Configure the machine for site travel, including maneuvering
                over uneven terrain, slopes, and confined spaces.
              </li>
              <li>
                Develop skills for loading, transporting, and unloading
                materials safely and efficiently.
              </li>
              <li>
                Perform excavation, backfilling, grading, and leveling tasks
                with precision.
              </li>
              <li>
                Identify and mitigate hazards such as underground services,
                overhead obstructions, and pedestrian activity.
              </li>
              <li>
                Learn proper stockpiling techniques and safe material handling
                methods.
              </li>
              <li>
                Follow proper loading and unloading procedures when transporting
                the machine.
              </li>
              <li>
                Execute safe shutdown, parking, and securing procedures to
                prevent accidents.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Candidates will be assessed through a combination of theoretical
              and practical examinations. The theoretical component includes a
              written test covering safety regulations, operational techniques,
              and hazard awareness.
            </p>
            <br />
            <p>
              The practical assessment requires candidates to demonstrate their
              ability to perform pre-use checks, maneuver the machine, complete
              excavation and loading tasks, and follow safe working practices.
            </p>
            <br />
            <p>
              All assessments are conducted under CPCS technical test
              conditions, ensuring candidates meet the required competency
              standards.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully passing the CPCS technical test, candidates will
              receive a Red CPCS Trained Operator Card, which remains valid for
              two years. This certification demonstrates competence in operating
              a skid steer loader within a construction environment.
            </p>
            <br />
            <p>
              To obtain a Blue CPCS Competence Card, candidates must complete an
              NVQ Level 2 in Plant Operations within the two-year validity
              period of their Red Card. The Blue CPCS Card serves as proof of
              professional competence for experienced operators.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A23 Skid Steer Loader course provides a structured learning
              experience, blending theoretical instruction with hands-on
              training to develop the essential skills required for skid steer
              loader operation. Candidates will gain confidence in handling the
              machine efficiently and safely in various working environments.
            </p>
            <br />
            <p>
              Whether you are new to the industry or an experienced operator
              looking to formalize your skills, this course offers valuable
              insights and hands-on experience to enhance your career prospects.
            </p>
            <br />
            <p>
              Enroll today to gain your CPCS certification and take the next
              step toward becoming a certified skid steer loader operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
