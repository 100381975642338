import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPilingRigTripod() {
  return (
    <>
      <Helmet>
        <title>A30 Piling Rig Tripod | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Piling Rig - Tripod A30 training course at Alpha Construction Training and gain essential skills and certification for piling rig operations."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A30 Piling Rig Tripod</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Piling Rig – Tripod A30 Training Course provides
              participants with essential knowledge and hands-on skills required
              for the safe and effective operation of piling rigs. Designed to
              meet industry standards, this course covers the fundamental
              principles of piling rig operations, focusing on safety,
              efficiency, and environmental considerations.
            </p>
            <br />
            <p>
              Whether you are new to the field or an experienced operator
              seeking formal certification, this course ensures you gain the
              necessary expertise to handle piling rigs with confidence. You
              will develop a thorough understanding of site assessments,
              equipment setup, rig positioning, and maintenance procedures.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Gain an in-depth understanding of piling rig operations and
                their role in construction projects.
              </li>
              <li>
                Learn about different types of piling rigs, their applications,
                and operational techniques.
              </li>
              <li>
                Understand and apply industry safety standards, including risk
                assessments and hazard mitigation strategies.
              </li>
              <li>
                Perform thorough pre-operational safety checks and routine
                maintenance to ensure equipment reliability.
              </li>
              <li>
                Learn how to travel with, position, and set up a Piling Rig –
                Tripod correctly for various site conditions.
              </li>
              <li>
                Assess ground stability, environmental factors, and soil
                conditions before commencing operations.
              </li>
              <li>
                Develop troubleshooting techniques to identify and resolve
                common mechanical or operational issues.
              </li>
              <li>
                Learn proper shutdown and securing procedures to maintain safety
                and extend equipment lifespan.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Candidates will be assessed through a structured evaluation
              process, which includes both theoretical and practical components.
              The written assessment tests knowledge of safety regulations,
              machine components, and operational procedures, while the
              practical assessment requires candidates to demonstrate their
              ability to operate the piling rig efficiently in a controlled
              environment.
            </p>
            <br />
            <p>
              To pass the assessment, candidates must exhibit a clear
              understanding of best practices, demonstrate safe rig setup and
              operation, and apply proper shutdown and maintenance procedures.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully completing the course and passing the CPCS
              technical assessment, participants will be awarded a Red CPCS
              Trained Operator Card. This certification is valid for two years
              and demonstrates competency in operating a Piling Rig – Tripod.
            </p>
            <br />
            <p>
              To upgrade to the Blue CPCS Competence Card, candidates must
              complete an NVQ Level 2 in Plant Operations within the two-year
              validity period of the Red Card. The Blue Card is renewable every
              five years and serves as an industry-recognized qualification for
              experienced operators.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Piling Rig – Tripod A30 Training Course is an essential
              program for operators looking to develop or formalize their
              skills. With a strong emphasis on safety, technical proficiency,
              and site efficiency, this course ensures that participants are
              well-prepared for real-world piling rig operations.
            </p>
            <br />
            <p>
              Whether you’re entering the construction industry or enhancing
              your qualifications, this course provides the knowledge and
              certification needed to advance your career. By completing this
              training, you will gain hands-on experience, meet CPCS standards,
              and improve your employability in the sector.
            </p>
            <br />
            <p>
              Enroll today and take the next step in mastering the operation of
              Piling Rig – Tripod equipment while working towards CPCS
              certification.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
