import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSideLoader() {
  return (
    <>
      <Helmet>
        <title>A15 Forklift Side-loader | Alpha Construction Training</title>
        <meta
          name="description"
          content="Master the operation of Forklift Side-loaders with the A15 CPCS Forklift Side-loader training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A15 Forklift Side-loader</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A15 Forklift Side-loader course is designed to equip learners
              with the essential knowledge and hands-on skills required for the
              safe and efficient operation of side-loader forklifts. This
              training ensures compliance with industry regulations and best
              practices, preparing participants for real-world material handling
              scenarios.
            </p>
            <br />
            <p>
              Training duration is determined based on individual experience
              levels, offering tailored learning paths for both novice operators
              and those with prior experience looking to gain formal
              certification.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the design, purpose, and operational characteristics
                of forklift side-loaders.
              </li>
              <li>
                Identify and explain the function of key components, controls,
                and safety mechanisms.
              </li>
              <li>
                Adhere to manufacturer guidelines, workplace safety regulations,
                and best practices in material handling.
              </li>
              <li>
                Conduct thorough pre-use inspections and routine maintenance
                checks.
              </li>
              <li>
                Plan and execute safe lifting, transporting, and depositing of
                long or oversized loads.
              </li>
              <li>
                Master controlled maneuvering techniques in confined spaces and
                busy work environments.
              </li>
              <li>
                Recognize and mitigate workplace hazards, ensuring compliance
                with health and safety standards.
              </li>
              <li>
                Efficiently load, unload, and stack materials at designated
                locations while maintaining load stability.
              </li>
              <li>
                Implement proper shut-down and securing procedures to ensure
                operational safety.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course incorporates a blend of theoretical instruction and
              practical exercises to develop proficiency in side-loader forklift
              operation. Assessment is conducted through CPCS technical tests,
              which include both a theory and practical evaluation.
            </p>
            <br />
            <p>
              Candidates will be required to demonstrate competency in operating
              the machine safely, performing required tasks, and adhering to
              best practices.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully completing the CPCS technical tests, candidates
              will be awarded a Red CPCS Trained Operator Card, which remains
              valid for two years. This certification serves as evidence of
              structured training and competency in operating a side-loader
              forklift.
            </p>
            <br />
            <p>
              To obtain the Blue CPCS Competence Card, candidates must complete
              an NVQ Level 2 in Plant Operations within the two-year validity
              period of their Red Card.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A15 Forklift Side-loader course is tailored to individuals
              seeking a professional qualification in material handling and
              logistics. Combining theoretical knowledge with hands-on training,
              the course ensures operators develop the necessary skills to
              navigate work environments safely and efficiently.
            </p>
            <br />
            <p>
              This course is ideal for those working in industries such as
              construction, warehousing, and logistics, where side-loader
              forklifts are commonly used to transport long and bulky materials.
            </p>
            <br />
            <p>
              Enroll today to gain industry-recognized certification and take
              the next step in your career as a certified forklift side-loader
              operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
