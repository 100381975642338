import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSMEWPSissor() {
  return (
    <>
      <Helmet>
        <title>A25 MEWP Scissor | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the A25 CPCS MEWP Scissor training course at Alpha Construction Training and become a certified operator in mobile elevating work platforms."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A25 MEWP Scissor</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS A25 MEWP Scissor training course is designed to equip
              participants with the theoretical knowledge and practical
              expertise required to safely operate scissor-type Mobile Elevating
              Work Platforms (MEWPs) in compliance with industry regulations.
            </p>
            <br />
            <p>
              This course focuses on safe operating procedures, hazard
              identification, emergency protocols, and effective maneuvering of
              scissor lifts in construction and industrial environments.
              Participants will gain essential skills for assessing worksite
              conditions, setting up equipment, and conducting operations at
              height while adhering to safety standards.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the purpose, function, and types of scissor lifts
                used in various industries.
              </li>
              <li>
                Recognize the responsibilities of an MEWP operator, including
                legal and safety obligations.
              </li>
              <li>
                Identify key machine components, control functions, and safety
                features.
              </li>
              <li>
                Conduct thorough pre-use inspections, safety checks, and
                emergency lowering function tests.
              </li>
              <li>
                Understand and follow the manufacturer’s operational guidelines
                and relevant regulations.
              </li>
              <li>
                Safely travel with a scissor lift across different surfaces,
                including inclines and confined spaces.
              </li>
              <li>
                Position and stabilize the platform for various working heights
                and applications.
              </li>
              <li>
                Operate the scissor lift effectively to access elevated work
                areas while maintaining safety compliance.
              </li>
              <li>
                Assess environmental hazards, including overhead and underground
                obstructions.
              </li>
              <li>
                Utilize extension decks safely and determine load distribution
                for stability.
              </li>
              <li>
                Execute proper shutdown procedures and secure the equipment
                after use.
              </li>
              <li>
                Follow correct procedures for loading, unloading, and
                transporting the MEWP scissor lift.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment process consists of both theoretical and practical
              evaluations. Candidates must complete a written examination
              covering safety protocols, operational techniques, and industry
              regulations.
            </p>
            <br />
            <p>
              Additionally, candidates will undergo a hands-on practical
              assessment, where they must demonstrate proficiency in pre-use
              inspections, safe maneuvering, platform positioning, and emergency
              response procedures.
            </p>
            <br />
            <p>
              Successful candidates will receive certification upon completing
              all assessment components to the required standard.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course, participants will be
              awarded a Red CPCS Trained Operator Card for the A25 MEWP Scissor
              category. This certification is valid for two years and serves as
              proof of training and competency.
            </p>
            <br />
            <p>
              To upgrade to the Blue CPCS Competence Card, candidates must
              complete an NVQ Level 2 in Plant Operations within the two-year
              validity period. The Blue CPCS Card is recognized across the
              industry as a qualification for experienced MEWP scissor lift
              operators.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A25 MEWP Scissor course is a structured training program that
              combines essential theoretical knowledge with hands-on practical
              instruction to ensure participants develop the necessary skills to
              operate scissor lifts safely and efficiently.
            </p>
            <br />
            <p>
              This course is suitable for individuals new to scissor lift
              operation as well as experienced operators seeking formal
              certification. It covers key aspects of machine controls, safety
              measures, hazard identification, and best practices for operating
              at height.
            </p>
            <br />
            <p>
              Enroll today to obtain your CPCS certification and advance your
              career in the construction and access equipment industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
