import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPlantDriving() {
  return (
    <>
      <Helmet>
        <title>A68 CPCS Plant Driving | Alpha Construction Training</title>
        <meta
          name="description"
          content="Master the skills required to operate plant machinery with the A68 CPCS Plant Driving course at Alpha Construction Training. Achieve industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A68 CPCS Plant Driving</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A68 CPCS Plant Driving course is designed to provide
              participants with the essential knowledge and practical skills
              required to safely operate a variety of plant machinery on
              construction sites. The training follows CPCS standards, ensuring
              that operators can handle equipment efficiently while complying
              with health and safety regulations.
            </p>
            <br />
            <p>
              This course focuses on best practices for plant operation,
              covering everything from equipment controls and pre-use checks to
              maneuvering in different site conditions. By the end of the
              course, participants will have a solid foundation in plant driving
              and safety protocols.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Develop an understanding of various types of plant machinery,
                including excavators, dumpers, rollers, and telehandlers.
              </li>
              <li>
                Learn how to conduct pre-use inspections to identify potential
                faults and ensure machinery is in safe working condition.
              </li>
              <li>
                Operate plant machinery effectively in different site
                environments, adapting to terrain and weather conditions.
              </li>
              <li>
                Understand and apply legal and regulatory requirements for plant
                operations, including workplace safety laws and environmental
                considerations.
              </li>
              <li>
                Implement risk assessment techniques and hazard management
                strategies to maintain a safe working environment.
              </li>
              <li>
                Improve communication and coordination with site personnel,
                ensuring clear signals and teamwork during operations.
              </li>
              <li>
                Practice load handling, material transport, and positioning of
                machinery to maximize efficiency on-site.
              </li>
              <li>
                Gain knowledge of emergency procedures, including shutdown
                protocols and responding to equipment malfunctions.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              Participants will be assessed through a combination of theoretical
              and practical evaluations to ensure they meet CPCS standards for
              plant driving. The assessment process includes:
            </p>
            <br />
            <ul>
              <li>
                A written or verbal knowledge test covering safety regulations,
                equipment operation, and hazard awareness.
              </li>
              <li>
                A hands-on practical assessment where participants must
                demonstrate their ability to operate plant machinery safely and
                efficiently in various site conditions.
              </li>
            </ul>
            <br />
            <p>
              To achieve certification, candidates must pass the final CPCS
              technical test, which evaluates their competency in plant driving
              under real-world scenarios.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and assessments,
              candidates will receive the CPCS Red Trained Operator Card for
              Plant Driving (A68), which is valid for two years. This
              certification is recognized industry-wide and serves as a
              foundation for further professional development.
            </p>
            <br />
            <p>
              Operators can progress to obtaining the CPCS Blue Competence Card
              by completing an NVQ Level 2 in Plant Operations. The Blue Card is
              valid for five years and demonstrates full competency in plant
              driving.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A68 CPCS Plant Driving course offers comprehensive training in
              operating plant machinery safely and efficiently. The course
              combines theoretical instruction with hands-on practice to ensure
              that participants are prepared for the demands of real-world
              construction environments.
            </p>
            <br />
            <p>
              Whether you are new to plant operation or looking to enhance your
              existing skills, this course provides the knowledge and experience
              necessary to succeed in the construction industry.
            </p>
            <br />
            <p>
              Enroll today to gain industry-recognized certification and take a
              significant step forward in your career as a skilled plant
              operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
