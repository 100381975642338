import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSlingerSignaller() {
  return (
    <>
      <Helmet>
        <title>Slinger Signaller A40 | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Slinger Signaller A40 training course at Alpha Construction Training to gain essential skills for safe and effective lifting operations."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>Slinger Signaller A40</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This Slinger Signaller CPCS training course is aimed at
              individuals seeking entry into the lifting industry. It equips
              participants with the skills and knowledge needed to carry out
              safe and effective slinging and signalling operations across
              various lifting scenarios. The course emphasizes safety
              regulations, best practices, and efficient communication in
              lifting operations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Establish the weight and dimensions of loads and communicate
                this information to the crane operator using industry-approved
                methods.
              </li>
              <li>
                Select appropriate lifting tackle based on load characteristics
                and attach and detach it correctly and safely.
              </li>
              <li>
                Use clear and unambiguous hand and radio signals to direct crane
                movements while handling loads of various weights and
                dimensions, ensuring safety and efficiency.
              </li>
              <li>
                Determine the serviceability of lifting accessories, recognize
                faults or damage, and ensure their proper maintenance and
                storage according to industry guidelines.
              </li>
              <li>
                Understand the importance of teamwork and effective
                communication in lifting operations to minimize risk and
                maximize efficiency on-site.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a combination of classroom-based theory,
              hands-on practical training, and interactive exercises to
              reinforce learning. The final assessment consists of a CPCS
              technical test, which evaluates both theoretical knowledge and
              practical competence. Candidates must demonstrate their ability to
              conduct safe lifting operations, interpret load charts, and
              effectively communicate using signals.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the technical tests, learners will
              receive a Red CPCS Trained Operator Card, valid for two years.
              This certification demonstrates competency in slinging and
              signalling tasks. To upgrade to a Blue CPCS Competence Card,
              participants must complete an NVQ Level 2 in Controlling Lifting
              Operations - Slinger Signaller within this two-year period. The
              Blue Card is renewable every five years through refresher training
              and continued professional development.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Slinger Signaller A40 course is designed to provide a
              thorough understanding of slinging and signalling practices,
              covering the latest health and safety regulations and industry
              standards. Participants will develop practical skills in load
              attachment, lifting signal communication, and risk assessment.
            </p>
            <br />
            <p>
              This course is suitable for both newcomers and experienced
              operators who need to formalize their skills with a recognized
              certification. By completing this training, individuals enhance
              their employability and contribute to safer lifting operations
              within the construction industry.
            </p>
            <br />
            <p>
              Enroll today to gain essential skills and certifications required
              to thrive in the lifting industry. Improve your career prospects
              and ensure compliance with lifting regulations by completing this
              industry-recognized training program.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
