import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSScreener() {
  return (
    <>
      <Helmet>
        <title>A43 Screener | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Screener A43 training course at Alpha Construction Training to gain essential skills for operating screeners safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A43 Screener</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The Screener A43 CPCS training course is designed to equip
              participants with the essential theoretical knowledge and hands-on
              practical experience required for the safe and efficient operation
              of screeners. The course ensures compliance with industry
              regulations and best practices while preparing operators for a
              variety of job site conditions.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the construction industry and the significance of
                proper screener operation in material processing and site
                management.
              </li>
              <li>
                Identify key components of a screener, including its mechanisms,
                control systems, and safety features.
              </li>
              <li>
                Interpret and adhere to manufacturer specifications, safety
                guidelines, and relevant regulations to ensure safe usage.
              </li>
              <li>
                Conduct comprehensive pre-use checks and routine maintenance to
                optimize machine performance and prevent breakdowns.
              </li>
              <li>
                Configure and maneuver the screener in various working
                environments, including tight spaces and uneven terrain.
              </li>
              <li>
                Operate the screener effectively to produce aggregates of
                different specifications while minimizing material wastage.
              </li>
              <li>
                Implement appropriate techniques for sorting materials into
                uniform sizes while maintaining production efficiency.
              </li>
              <li>
                Utilize correct communication protocols and site signals to
                coordinate with other workers safely and efficiently.
              </li>
              <li>
                Recognize common operational challenges such as blockages and
                material contamination, and apply safe troubleshooting methods.
              </li>
              <li>
                Execute proper shutdown, cleaning, and securing procedures to
                prolong machine lifespan and ensure workplace safety.
              </li>
              <li>
                Explain best practices for loading, transporting, and assembling
                the screener at various job sites.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undergo a blended assessment process consisting
              of theoretical knowledge evaluations, practical demonstrations,
              and an official CPCS technical test. The assessment aims to
              validate the candidate’s competency in operating screeners in
              compliance with industry standards.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course and CPCS technical test,
              candidates will be awarded the Red CPCS Trained Operator Card,
              which remains valid for two years. To obtain the Blue CPCS
              Competence Card, operators must complete an NVQ Level 2 in Plant
              Operations within the validity period of the Red Card. The Blue
              Card is renewable every five years, ensuring ongoing competency in
              screener operations.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Screener A43 course provides in-depth training in the
              safe and efficient operation of screeners, ensuring compliance
              with regulatory standards and workplace safety requirements. The
              course caters to both novice and experienced operators seeking to
              enhance their professional credentials and job prospects.
            </p>
            <br />
            <p>
              Through structured theoretical lessons and hands-on practical
              exercises, candidates will develop a solid understanding of
              screener functionalities, maintenance procedures, and material
              processing techniques.
            </p>
            <br />
            <p>
              This certification enhances employability within the construction
              and recycling industries, offering opportunities for career
              advancement and greater job security. Enroll today to take the
              next step in your professional journey as a skilled screener
              operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
