import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTowerCrane() {
  return (
    <>
      <Helmet>
        <title>A04 Tower Crane | Alpha Construction Training</title>
        <meta
          name="description"
          content="Master the skills required to operate Tower Cranes with the A04 CPCS Tower Crane training course at Alpha Construction Training. Achieve industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A04 Tower Crane</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A04 Tower Crane training course is designed to provide
              participants with the skills and theoretical knowledge required to
              operate Tower Cranes safely and efficiently in accordance with
              CPCS standards. The training covers essential operational
              procedures, regulatory requirements, and industry best practices
              for handling loads in construction environments.
            </p>
            <br />
            <p>
              This course is suitable for individuals seeking to work as tower
              crane operators, ensuring they understand safety protocols,
              machine controls, and lifting operations to minimize risk and
              enhance efficiency on-site.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Gain an in-depth understanding of the construction industry and
                the responsibilities of a Tower Crane operator in a controlled
                work environment.
              </li>
              <li>
                Identify key components of a Tower Crane, including its
                structure, operational controls, and associated terminology.
              </li>
              <li>
                Understand legal and manufacturer guidelines to ensure safe
                operations in compliance with industry standards.
              </li>
              <li>
                Perform thorough pre-use inspections to verify the crane’s
                functionality and safety before operation.
              </li>
              <li>
                Learn the correct procedures for setting up and configuring the
                crane for lifting duties, including selecting the appropriate
                lifting accessories.
              </li>
              <li>
                Develop skills in executing controlled and precise lifting
                techniques to prevent load instability and ensure smooth
                material handling.
              </li>
              <li>
                Master the art of load balance, ensuring proper placement of
                materials while minimizing risks such as swinging or tipping.
              </li>
              <li>
                Implement safe crane travel methods, including monitoring
                movements, avoiding hazards, and responding to emergency
                situations effectively.
              </li>
              <li>
                Recognize the impact of environmental conditions, including wind
                speed and other weather-related factors, on crane stability and
                load handling.
              </li>
              <li>
                Understand communication protocols with ground personnel, signal
                operators, and site supervisors to ensure seamless operation.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              Candidates will be assessed through a combination of theoretical
              and practical evaluations to demonstrate competency in Tower Crane
              operations. The assessment includes:
            </p>
            <br />
            <ul>
              <li>
                A written or verbal knowledge test covering site safety, crane
                controls, lifting procedures, and hazard awareness.
              </li>
              <li>
                A practical examination where candidates must successfully
                operate a Tower Crane under controlled conditions, demonstrating
                proper lifting techniques and adherence to safety protocols.
              </li>
            </ul>
            <br />
            <p>
              The final CPCS technical test will be conducted to evaluate
              competency and issue certification upon successful completion.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon passing the CPCS technical test, participants will receive
              the CPCS Red Trained Operator Card for Tower Crane (A04). This
              card is valid for two years and serves as a foundation for further
              professional development.
            </p>
            <br />
            <p>
              To progress in their career, operators can complete an NVQ Level 2
              in Plant Operations, which qualifies them for the CPCS Blue
              Competence Card—an advanced certification widely recognized in the
              construction industry.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A04 CPCS Tower Crane training course provides comprehensive
              instruction in safe and effective crane operation. By combining
              theoretical knowledge with practical experience, participants will
              develop the expertise required to operate Tower Cranes safely in
              busy construction environments.
            </p>
            <br />
            <p>
              Whether you are looking to start a career in crane operation or
              enhance your existing skills, this certification will prepare you
              for real-world scenarios while ensuring compliance with industry
              standards.
            </p>
            <br />
            <p>
              Enroll today to gain industry-recognized certification and take a
              significant step forward in your career as a professional Tower
              Crane operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
