import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSExcavator180Above5T() {
  return (
    <>
      <Helmet>
        <title>A12 CPCS Excavator 180º Above 5 Tonnes</title>
        <meta
          name="description"
          content="Gain expertise in operating Excavators with the Excavator 180º above 5 tonnes - A12 training course at Alpha Construction Training. Achieve industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A12 CPCS Excavator 180º Above 5 Tonnes</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The A12 CPCS Excavator 180º Above 5 Tonnes course is designed to
              equip participants with the essential skills and theoretical
              knowledge required to safely and effectively operate a 180º
              excavator above 5 tonnes. This training follows CPCS guidelines
              and industry best practices, ensuring operators understand machine
              controls, excavation techniques, and site safety requirements.
            </p>
            <br />
            <p>
              Whether you're a new operator or looking to enhance your existing
              qualifications, this course provides structured training to meet
              competency standards in construction and plant operation.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Gain an in-depth understanding of the construction industry's
                regulations, safety requirements, and the responsibilities of a
                plant operator.
              </li>
              <li>
                Learn about the key components of a 180º excavator, including
                machine controls, hydraulic systems, and attachments.
              </li>
              <li>
                Conduct pre-use inspections and perform routine maintenance to
                ensure safe operation and longevity of the equipment.
              </li>
              <li>
                Interpret and apply safety regulations, risk assessments, and
                method statements relevant to excavator operations.
              </li>
              <li>
                Prepare the machine for on-site work and highway travel,
                ensuring compliance with transport regulations.
              </li>
              <li>
                Develop skills for maneuvering the excavator across different
                terrains, including slopes, confined spaces, and uneven ground.
              </li>
              <li>
                Perform excavation tasks such as trenching, backfilling,
                grading, and material handling while maintaining efficiency and
                precision.
              </li>
              <li>
                Attach and detach excavator buckets and additional components
                safely, following correct operational procedures.
              </li>
              <li>
                Understand load handling principles, lifting capacity, and
                machine stability factors when working with heavy materials.
              </li>
              <li>
                Follow shutdown procedures, secure the machine correctly, and
                prepare it for transportation where necessary.
              </li>
            </ul>
          </div>

          <div className="learning-outcomes">
            <h2>Assessment</h2>
            <p>
              To successfully complete the course, candidates must undergo both
              theoretical and practical assessments. The evaluation includes:
            </p>
            <br />
            <ul>
              <li>
                A written or verbal test covering operational principles, health
                and safety requirements, and excavator components.
              </li>
              <li>
                A practical test requiring candidates to demonstrate their
                ability to operate the excavator safely, complete excavation
                tasks, and follow safety protocols.
              </li>
            </ul>
            <br />
            <p>
              The final assessment is conducted under CPCS standards to ensure
              operators meet industry competency levels.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon passing the CPCS technical tests, participants will receive
              the CPCS Red Trained Operator Card, which is valid for two years.
              This card certifies their ability to operate a 180º excavator
              above 5 tonnes in compliance with industry regulations.
            </p>
            <br />
            <p>
              To advance their certification, operators can complete an NVQ
              Level 2 in Plant Operations, enabling them to apply for the CPCS
              Blue Competence Card, which serves as a long-term qualification.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A12 CPCS Excavator 180º Above 5 Tonnes course provides a
              thorough combination of theoretical instruction and hands-on
              training, ensuring operators develop the necessary expertise to
              work efficiently and safely. Participants will gain insight into
              best practices for excavating, load handling, and maintaining
              stability while working in diverse site conditions.
            </p>
            <br />
            <p>
              This training is ideal for those working in civil engineering,
              groundworks, and construction, where excavators are essential for
              daily operations.
            </p>
            <br />
            <p>
              Enroll today and take the next step toward earning an
              industry-recognized certification that enhances your career
              opportunities in the construction and plant operation sector.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
